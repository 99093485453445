import tools from '../utils/tools'
import {kimi} from '../utils/request';

export const lookFile = async (fileid) => {
  return kimi.get(`/files/${fileid}/content`)
}

export const chatCompletions = async (messages) => {
  return await kimi.post('/chat/completions', {
    data: {messages, model: 'moonshot-v1-auto', temperature: 0.3, tools}
  })
}

export const createCache = async (cache_tag, messages) => {
  return kimi.post('/caching', {
    data: {messages, model: 'moonshot-v1', ttl: 3600, tags: [cache_tag]}
  })
}

export const loadTag = async (tagname) => {
  return kimi.get(`/caching/refs/tags/${tagname}`)
}