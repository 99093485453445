import {action, computed, observable, runInAction} from "mobx";
import {ExtendApi, KimiApi} from "../api";
import * as Tools from '../utils/tools'
import {CHAT_ROLE, KNOWLEDGE_ID} from "../utils/constants";

const CACHE_NAME = 'knowledge'
const USE_CONTEXT_CACHE = false;

export class ChatGptStore {

  @observable chatlist = [];
  @observable config = {}
  @observable banners = []
  @observable cacheMessages = []
  @observable knowledge = []

  constructor() {
    this.loadConfig();
    this.initKnowleage();
  }

  @computed get waiting() {
    if (this.chatlist.length > 0) {
      return this.chatlist.some(item => item.waiting);
    }
    return false
  }

  @computed get ready() {
    return !!this.config?.system
  }

  @computed get idle() {
    return this.ready && !this.waiting
  }

  @computed get shortcuts() {
    return this.config?.shortcuts || []
  }

  @computed get lastAssistant() {
    const assistants = this.chatlist.filter(item => item.role === 'assistant' && !item.waiting)
    return assistants.length > 0 ? assistants[assistants.length - 1] : null
  }

  @action
  loadConfig = async () => {
    const config = await ExtendApi.loadStaticFile('prompt/config.json', {name: "prompt_config"});
    const banners = await ExtendApi.loadStaticFile('banner/config.json', {name: 'banner_config'});
    runInAction(() => {
      this.config = config[CHAT_ROLE] || config.xiaoji;
      this.banners = banners
      document.title = `吉富${this.config.name}`
    })
  }

  initKnowleage = async () => {
    if (USE_CONTEXT_CACHE) {
      try {
        await KimiApi.loadTag(CACHE_NAME);
      } catch (e) {
        const {content} = await KimiApi.lookFile(KNOWLEDGE_ID);
        await KimiApi.createCache(CACHE_NAME, [{role: "system", content}]);
      }
      this.knowledge = [{role: "cache", content: `tag=${CACHE_NAME};reset_ttl=3600`}]
    } else {
      const {content} = await KimiApi.lookFile(KNOWLEDGE_ID);
      this.knowledge = [{role: "system", content}]
    }
  }

  @action
  sendChat = async (text, label) => {
    this.chatlist = [...this.chatlist, {
      role: 'user',
      content: text,
      label,
    }, {
      role: 'assistant',
      waiting: true
    }]
    let messages = this.chatlist.filter(item => !item.waiting).map(item => ({role: item.role, content: item.content}))
    if (this.config.messageLimit > 0) {
      messages = messages.filter((_, index) => index >= messages.length - this.config.messageLimit)
    }
    messages = [...this.knowledge, {
      role: 'system',
      content: this.config.system
    }, ...messages]

    let finish_reason
    do {
      const {choices: [{finish_reason: reason, message}], id} = await KimiApi.chatCompletions(messages);
      finish_reason = reason
      messages.push({id, ...message})
      if (finish_reason === 'tool_calls') {
        for (const tool_call of message.tool_calls) {
          let content
          if (tool_call.function.name === '$web_search') {
            content = JSON.parse(tool_call.function.arguments)
          } else {
            content = await Tools[tool_call.function.name](JSON.parse(tool_call.function.arguments))
          }
          messages.push({
            role: "tool",
            tool_call_id: tool_call.id,
            name: tool_call.function.name,
            content: JSON.stringify(content)
          })
        }
      }
    } while (finish_reason === 'tool_calls')

    const [result] = messages.reverse()
    this.chatlist = this.chatlist.map((item, index) => {
      return index === this.chatlist.length - 1 ? result : item
    })
  }

  @action
  reset = () => {
    this.chatlist = []
  }

}


export default new ChatGptStore();