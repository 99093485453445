import ivh from '../libs/txivh.min';
import {useEffect, useState} from "react";
import {TX_DIGITAL_HUMAN} from "../utils/constants";
import {error} from "../utils/common";

const {APPKEY: appKey, TOKEN: accessToken} = TX_DIGITAL_HUMAN
ivh.setPrivatization({appKey, accessToken})

export const useDigitalHuman = ({element, project: virtualmanProjectId, autoplay = false, driverType = 1}) => {

  const [data, setData] = useState({status: 'init'})

  useEffect(() => {
    initial()
  }, []);

  const initial = async () => {
    ivh.init({element, virtualmanProjectId})
    ivh.on('socket', (e) => {
      if (e.errorCode === 0 && e.type === 3) {
        switch (e.speakStatus) {
          case 'TextStart':
            setData({...data, status: 'speaking'})
            break;
          case 'TextOver':
            setData({...data, status: 'idle'})
            break;
        }
      }
    })
    ivh.on('error', e => {
      switch (e.code) {
        case 100008:
          error('超过配额限制')
          setTimeout(() => {
            close()
          })
      }
    })
  }

  const start = async () => {
    setData(data => ({...data, status: 'starting'}))
    const result = await ivh.createSession({driverType});
    setData(data => ({...data, ...result, status: 'ready'}))
    autoplay && await start()
    if (await ivh.startSession()) {
      setData(data => ({...data, status: 'idle'}))
    }
  }

  const speak = (data) => {
    ivh.play({
      command: 'text', data
    })
    setData({...data, status: 'sendtext'})
  }

  const interrupt = () => {
    ivh.stop()
  }

  const close = () => {
    try {
      ivh.closeSession();
    } finally {
      setData(datas => ({...datas, status: 'init'}))
    }
  }

  return {
    status: data.status,
    start,
    close,
    speak,
    interrupt
  }

}

