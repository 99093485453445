import {SPEECH_APP_KEY} from "../utils/constants";
import {speechStore} from '../stores';
import {demo, speech} from "../utils/request";
import qs from 'query-string';


export const getToken = async () => {
  const response = await demo.get('/speech/token')
  return response.body
}

export const convertAudio = async (blob, format, query) => {
  const body = new FormData();
  body.append("file", blob, "audio.webm");
  const path = qs.stringifyUrl({
    url: `/speech/convert/${format}`, query
  }, {skipEmptyString: true, skipNull: true})
  return await demo.post(path, {
    requestType: 'formData', body, responseType: 'blob'
  });
}

export const recognize = async (blob) => {
  const qyery = qs.stringify({
    format: 'wav',
    appkey: SPEECH_APP_KEY
  })
  return await speech.post(`/asr?${qyery}`, {
    requestType: 'blob',
    headers: {
      'X-NLS-Token': speechStore.token,
      'Host': 'nls-gateway.aliyuncs.com',
      'Content-type': 'application/octet-stream',
      'Content-Length': blob.size
    },
    body: blob
  })
}

export const synthesize = async (text, voice = "zhida") => {
  return await speech.post('/tts', {
    responseType: 'blob',
    data: {
      text, appkey: SPEECH_APP_KEY, token: speechStore.token, format: "mp3", voice
    }
  })
}