/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import {extend, ResponseError} from 'umi-request';
import {API_PATH, DEMO_PATH, KIMI_PATH, SPEECH_PATH} from './constants';
import {toast} from './common';

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '请求参数错误。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
  NOT_FOUND: '需要的数据查找不到',
};

export class ApiError extends Error {
  constructor(status, reason, message) {
    super(reason);
    this.status = status;
    this.text = message;
  }
}

/**
 * 异常处理程序
 */
const errorHandler = async (error) => {
  if (error instanceof ResponseError) {
    const {response = {}} = error;
    const {status} = response;

    const body = await response.json();
    const errortext = codeMessage[status] || body.message;
    toast(errortext);
  }
  throw error;
};

/**
 * 配置request请求时的默认参数
 */
const request = extend({
  prefix: API_PATH,
  errorHandler, // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie,
});

export default request;

export const speech = extend({
  charset: 'utf-8',
  prefix: SPEECH_PATH,
})

export const kimi = extend({
  charset: 'utf-8',
  prefix: KIMI_PATH
})

export const demo = extend({
  charset: 'utf-8',
  prefix: DEMO_PATH
})