import {useEffect, useRef, useState} from "react";

export const useAudioRecorder = () => {

  const {current: cache} = useRef({chunks: []});
  const [blob, setBlob] = useState();
  const [status, setStatus] = useState("idle")


  useEffect(() => {
    if (!cache.stream) {
      createMediaStream().catch(() => {
        setStatus("unsupport")
      })
    }
    return () => {
      if (status === 'recording') {
        stopRecording()
      }
      if (cache.stream) {
        cache.stream.getTracks().forEach((track) => track.stop());
      }
    }
  }, []);

  const createMediaStream = async () => {
    if (!navigator.mediaDevices?.getUserMedia || !window.MediaRecorder) {
      throw new Error("unsupport recorder")
    }
    cache.stream = await navigator.mediaDevices.getUserMedia({audio: true});
  }

  const startRecording = async () => {
    try {
      setStatus("recording");
      if (!cache.stream) {
        await createMediaStream();
      }
      if (cache.stream) {
        if (cache.stream.getTracks().some(track => track.readyState === "ended")) {
          await createMediaStream();
        }
      }
      if (!cache.stream.active) {
        return
      }
      const recorder = new MediaRecorder(cache.stream);
      recorder.addEventListener("dataavailable", ({data}) => cache.chunks.push(data))
      recorder.addEventListener("stop", async () => {
        const [chunk] = cache.chunks;
        const webm = new Blob(cache.chunks, {type: chunk.type});
        setBlob(webm);
        setStatus("stopped");
      })
      recorder.start();
      cache.recorder = recorder;
      setStatus(status => {
        if (status === 'stopping') {
          setTimeout(stopRecording, 100)
        }
        return status;
      })
    } catch (e) {
      setStatus("idle")
    }
  }

  const stopRecording = () => {
    setStatus("stopping");
    if (cache.recorder && cache.recorder.state !== 'inactive') {
      cache.recorder.stop();
      cache.stream && cache.stream.getTracks().forEach(track => track.stop());
      cache.chunks = [];
    }
  }


  return {
    status,
    blob,
    startRecording,
    stopRecording,
  }

}