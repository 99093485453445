import React from "react";
import {observer} from 'mobx-react';
import {Redirect} from "react-router-dom";
import {useStores} from "./hooks";

const DomainRoute = observer((props) => {
  const {chatgptStore} = useStores();
  const {config} = chatgptStore;
  if (config.route) {
    return (
      <Redirect {...props} to={`/${config.route}`}/>
    );
  }
  return props.children
})

const routes = [{
  path: '/',
  Route: DomainRoute,
  exact: true
}, {
  path: '/chat',
  component: './pages/chat.js',
  exact: true
}, {
  path: '/digital-human',
  component: './pages/digital-human.js',
  exact: true
}];

export default routes;
