export const FILE_PATH = process.env.REACT_APP_FILE_PATH;
export const API_PATH = process.env.REACT_APP_API_PATH;
export const TIME_PATH = process.env.REACT_APP_TIME_PATH;
export const KIMI_PATH = process.env.REACT_APP_KIMI_PATH;
export const SPEECH_PATH = process.env.REACT_APP_SPEECH_PATH;
export const DEMO_PATH = process.env.REACT_APP_DEMO_PATH;
export const CHAT_ROLE = process.env.REACT_APP_CHAT_ROLE || window.location.hostname.split(".")[0]
export const KNOWLEDGE_ID = "cshi69804rj08lu543og"
export const SPEECH_APP_KEY = "pXeWsTkMglRj0eZx"
export const STATIC_URI = 'https://cbgpt-static.oss-cn-shanghai.aliyuncs.com/cfb/uat/';
export const TX_DIGITAL_HUMAN = {
  APPKEY: "2b0988a00b19482795027504c4cbda09",
  TOKEN: "0501593f4ab24c9c84ce1cafe0ea85f9",
  PROJECT: "7f2ae3a071c44fcd85c69802a00bc81f"
}

