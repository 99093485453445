import {action, observable} from "mobx";
import {SpeechApi} from "../api";


export class SpeechStore {

  @observable token = process.env.REACT_APP_SPEECH_TOKEN;

  constructor() {
    if (!this.token) {
      this.loadToken()
    }
  }

  @action
  loadToken = async () => {
    this.token = await SpeechApi.getToken();
  }

}

export default new SpeechStore();